<template>
  <div>

    <div class="container">
      <div class="row ">
        <div class="col-12 align-content-center justify-content-center">
          <h1>{{document_name}}</h1>
        </div>
      </div>
    </div>

    <div class="container">
      <a class="btn btn-primary" @click="downloadFile">Скачать</a>
      <br><br>

      <h3>Результат</h3>
        <div v-html="text_transcribe"></div>

      </div>




    </div>







</template>

<script>
import store from "@/store/store";
import { MessageCircleIcon, BookIcon, DownloadIcon } from "vue-feather-icons";

export default {
  components: {
    MessageCircleIcon, BookIcon, DownloadIcon,
  },
  name: "WhiperXDocument",
  data() {
    return {

      input_api: {user_id: 1,document_hash:''},


      text_transcribe:'',document_name:'',

    }
  },



  mounted() {
    let hash = this.$route.params.hash;
    this.getDocumentText(hash);
  },
  methods: {



    downloadFile() {

      this.input_api.user_id = store.state.user_id;

      this.axios({
        method: 'get',
        url: '' + this.$hostnamejava + '/whisperx/docx/'+this.input_api.user_id+'/'+this.$route.params.hash,
        responseType: "blob",
      }).then(function (response) {

        //console.log(response.headers.toJSON())
        const blob = new Blob(
          [response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document;charset=utf-8'});
        const aEle = document.createElement('a');     // Create a label
        const href = window.URL.createObjectURL(blob);       // Create downloaded link
        aEle.href = href;
        aEle.download = "transcription.docx";  // File name after download
        document.body.appendChild(aEle);
        aEle.click();     // Click to download
        document.body.removeChild(aEle); // Download complete remove element
        window.URL.revokeObjectURL(href) // Release blob object
      })

    },


    getDocumentText(hash) {

      this.input_api.user_id = store.state.user_id;
      this.input_api.document_hash = hash;

      this.axios({
        method: 'post',
        url: '' + this.$hostnamejava + '/whisperx/get-document-text',
        data: this.input_api,
      }).then((res) => {

        this.text_transcribe = res.data.transcription;
        this.document_name = res.data.document_name;

      });

    },

  }
}
</script>

<style scoped></style>